import { Link } from "react-router-dom";

import logo from "../static/images/logo-imagen-oscuro.webp";
import lawyers from "../constants/lawyers";

const Heading = (props) => {
  return (
    <header className="v3-header">
      <div className="v3-header-top v4-header-top">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="v-3-header-contact ">
                <ul style={{ display: "flex", justifyContent: "center" }}>
                  <li>
                    <span>Email :</span> contacto@ayhabogados.com
                  </li>
                  <li>
                    {" "}
                    <span
                      className="glyphicon glyphicon-phone-alt"
                      aria-hidden="true"
                    ></span>{" "}
                    Tel: (1) 4323981 En Bogotá
                  </li>
                  <li>
                    <a href="/contacto">Contáctanos</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="v3-header-bg">
        <div className="container custom-header">
          <div className="row" style={{ paddingBottom: "10px" }}>
            <div className="v-3-menuzord clearfix">
              <div id="menuzord" className="menuzord">
                <a
                  href="/"
                  className="menuzord-brand texto_auxiliar"
                  style={{ fontSize: "19px" }}
                >
                  <img src={logo} width="80" height="70" alt="" />
                </a>
                <a
                  href="/"
                  className="menuzord-brand texto_auxiliar"
                  style={{ fontSize: "19px" }}
                >
                  Alvarez & Hernández<p style={{ color: "black" }}>ABOGADOS</p>
                </a>
                <ul
                  className="menuzord-menu header-v3-menuzord-menu menuzord-menu-bg"
                  style={{ fontSize: "20px" }}
                >
                  <li>
                    <a href="/">INICIO</a>
                  </li>
                  <li>
                    <Link to="/sobre-nosotros">SOBRE NOSOTROS</Link>
                  </li>
                  <li>
                    <Link to="/areas-practica">ÁREAS DE PRÁCTICA</Link>
                  </li>
                  <li>
                    <Link to="/abogados">ABOGADOS </Link>
                    <ul className="dropdown">
                      {lawyers.map((lawyer, idx) => (
                        <li key={lawyer.name + idx}>
                          <Link
                            to={`/abogados/${lawyer.name}`}
                            onClick={() => {
                              props.handleUpdate();
                            }}
                          >
                            {lawyer.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <Link to="/contacto">CONTACTO</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Heading;
