import React, { useState } from "react";
import logo from "../static/images/logo-imagen-oscuro.webp";
import GoogleApiWrapper from "./MapMarker";
import lawyers from "../constants/lawyers";
import { Link } from "react-router-dom";

const Footer = () => {
  const [visibleMap, setVisibleMap] = useState(true);
  return (
    <>
      <footer className="footer-area">
        <div className="footer-head">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 col-md-3">
                <div className="f-h-content">
                  <h3>Llámanos !</h3>
                  <h2>(1) 4323981</h2>
                  <p>
                    <a href="/">Horario de Oficina</a>
                  </p>
                </div>
              </div>
              <div className="col-sm-5 col-md-5">
                <div className="f-h-content">
                  <h3>Envíanos un mensaje !</h3>
                  <h2>
                    <a href="mailto:contacto@ayhabogados.com">
                      contacto@ayhabogados.com
                    </a>
                  </h2>
                  <p>
                    <a href="/">24/7 Disponible</a>
                  </p>
                </div>
              </div>
              <div id="dir" className="col-sm-4 col-md-4">
                <div className="f-h-content">
                  <h3>Visitanos !</h3>
                  <h2>Edificio Casa de Bolsa, Calle 33 # 6b-24 Oficina 501</h2>
                  <div className="asset-button text-center;">
                    <button
                      onClick={() => setVisibleMap(!visibleMap)}
                      className="btn btn-default "
                    >
                      Ver/Ocultar Mapa
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                {visibleMap && (
                  <GoogleApiWrapper
                    style={{
                      maxWidth: "100%",
                      height: "250px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="footer-content"
          style={{ paddingTop: "10px", marginTop: visibleMap ? "270px" : 0 }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-7 col-md-5">
                <div className="f-first-content f-all-h2">
                  <div className="f-content-img">
                    <img
                      style={{ width: "90px", height: "80px" }}
                      src={logo}
                      alt="f-"
                    />
                  </div>
                  <p>
                    En A&H contamos con la capacidad y el deseo de abordar los
                    problemas legales más desafiantes y abogar totalmente por
                    los derechos de los demandantes.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-md-3">
                <div className="f-second-content f-all-h2">
                  <h2>Horario de Oficina</h2>
                  <ul>
                    <li>
                      <a href="/">{"Lunes - Viernes : 8 am to 7 pm"}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="f-third-content f-all-h2">
                  <h2>Abogados Asociados</h2>
                  <ul>
                    {lawyers.map((lawyer, idx) => (
                      <li key={idx}>
                        <Link to={`/abogados/${lawyer.name}`}>
                          <i className="fa fa-long-arrow-right"></i>
                          {lawyer.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="footer-bottom clearfix">
              <div className="col-md-12 col-sm-12 no-padding-left">
                <div className="f-bottom-left-text">
                  <p
                    style={{
                      fontSize: "13px",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    {
                      "Alvarez & Hernández Abogados © Todos los derechos reservados. "
                    }
                    {"Elaborado por Cesar Ochoa "}
                    <span>
                      <a href="mailto:cesarochoa2006@hotmail.com">
                        {"cesarochoa2006@hotmail.com"}
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
