import Footer from "./layout/footer";
import Heading from "./layout/heading";
import { Route, Switch } from "react-router-dom";
import { Suspense, useState, lazy } from "react";
import { useHistory } from "react-router";
import Home from "./pages/home";

const About = lazy(() => import("./pages/about"));
const PracticeAreas = lazy(() => import("./pages/practice"));
const Lawyers = lazy(() => import("./pages/lawyers"));
const DetailLawyer = lazy(() => import("./pages/detailLawyer"));
const Contact = lazy(() => import("./pages/contact"));

const Preloader = () => (
  <div id="preloader">
    <div className="loader_lawyer">
      <img
        src="/src/static/images/hummer.svg"
        alt="loader img"
        className="hummer"
      />
      <img
        src="/src/static/images/plate.svg"
        alt="loader img"
        className="plate"
      />
    </div>
  </div>
);

function App() {
  const [, update] = useState();
  const handleUpdate = () => update({});
  useHistory().listen(() => window.scrollTo(0, 0));

  return (
    <div className="App">
      <Suspense fallback={<Preloader />}>
        <Heading handleUpdate={handleUpdate} />
        <Switch>
          <Route exact path="/">
            <Home handleUpdate={handleUpdate} />
          </Route>
          <Route exact path="/sobre-nosotros">
            <About />
          </Route>
          <Route exact path="/areas-practica">
            <PracticeAreas />
          </Route>
          <Route exact path="/abogados">
            <Lawyers />
          </Route>
          <Route exact path="/abogados/:name">
            <DetailLawyer />
          </Route>
          <Route exact path="/contacto">
            <Contact />
          </Route>
        </Switch>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
