import bgTeam from "../../static/images/bg-team.webp";
import sld1 from "../../static/images/slider.webp";
import titlev5 from "../../static/images/page-title-v5.webp";
import logoImg from "../../static/images/logo-imagen-oscuro.webp";
import "./styles.css";
import news from "../../constants/news";
import lawyers from "../../constants/lawyers";
import { Link } from "react-router-dom";

const Home = (props) => {
  return (
    <>
      <div className="rev_slider_wrapper">
        <div id="slider1" className="rev_slider" data-version="5.0">
          <ul>
            <li
              data-index="rs-6"
              data-transition="parallaxtoright"
              data-slotamount="default"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-delay="6510"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-description=""
            >
              <img
                src={bgTeam}
                alt="equipo"
                width="1920"
                height="1280"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                data-bgparallax="off"
                className="rev-slidebg"
                data-no-retina
              />
              <div
                className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0 captionText"
                id="slide-6-layer-1"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['180','180','180','180']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="x:-50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="x:50px;opacity:0;s:500;s:500;"
                data-start="100"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
              >
                <span className="sl-italic"></span>
              </div>

              <div
                className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0 captionText1"
                id="slide-6-layer-2"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['225','225','225','225']"
                data-width="none"
                data-height="none"
                data-fontsize="['50','40','40','25']"
                data-lineheight="['58','40','40','30']"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="x:-50px;opacity:0;s:500;s:500;"
                data-start="700"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
              >
                <div
                  className="text-center heading-rp-small"
                  style={{ textTransform: "uppercase" }}
                >
                  Tus soluciones legales <br />
                  comienzan aquí{" "}
                </div>
              </div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-0"
                id="slide-6-layer-3"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['364','364','364','364']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1200"
                data-responsive_offset="on"
                style={{ zIndex: 7 }}
              ></div>

              <div
                className="tp-caption small_text tp-resizeme rs-parallaxlevel-0"
                id="slide-6-layer-4"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['428','428','428','428']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1200"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{ zIndex: 8, whitespace: "nowrap" }}
              >
                <a
                  className="btn btn-default-alt rev-slider-btn-1"
                  href="/sobre-nosotros"
                >
                  QUIENES SOMOS
                </a>
              </div>
            </li>
            <li
              data-index="rs-7"
              data-transition="parallaxtoright"
              data-slotamount="default"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-delay="6500"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-description=""
            >
              <img
                src={sld1}
                alt=""
                width="1920"
                height="1022"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                data-bgparallax="off"
                className="rev-slidebg"
                data-no-retina
              />

              <div
                className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0 captionText2"
                id="slide-7-layer-1"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['102','102','102','102']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:-50px;opacity:0;s:500;s:500;"
                data-start="100"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
              >
                <span className="sl-italic">
                  ¡Manejamos tus asuntos jurídicos y legales!
                </span>
              </div>
              <div
                className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0 captionText1"
                id="slide-7-layer-2"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['148','148','148','148']"
                data-width="none"
                data-height="none"
                data-fontsize="['50','40','35','20']"
                data-lineheight="['58','40','40','30']"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="700"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
              >
                <div
                  className="text-center heading-rp-small"
                  style={{ textTransform: "uppercase" }}
                >
                  Somos Especialistas En
                  <br />
                  Defender tus derechos
                </div>
              </div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-0"
                id="slide-7-layer-3"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['280','280','280','280']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:-50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1200"
                data-responsive_offset="on"
                style={{ zIndex: 7 }}
              ></div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-0"
                id="slide-7-layer-5"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['344','344','344','344']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1200"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{ zIndex: 8, whitespace: "nowrap" }}
              >
                <a
                  className="btn btn-default-alt rev-slider-btn-1"
                  href="/areas-practica"
                >
                  ÁREAS DE PRÁCTICA
                </a>
              </div>
            </li>
            <li
              data-index="rs-14"
              data-transition="parallaxtoright"
              data-slotamount="default"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-delay="6510"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slider3-Layer1"
              data-description=""
            >
              <img
                src={titlev5}
                alt=""
                width="2000"
                height="1333"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                data-bgparallax="off"
                className="rev-slidebg"
                data-no-retina
              />
              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-0"
                id="slide-14-layer-13"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['140','140','140','140']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:-50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:-50px;opacity:0;s:500;s:500;"
                data-start="300"
                data-responsive_offset="on"
                style={{ zIndex: 5 }}
              >
                <img
                  src={logoImg}
                  alt=""
                  width="110"
                  height="100"
                  data-ww="['110px','110px','110px','110px']"
                  data-hh="['100px','100px','100px','100px']"
                  data-no-retina
                />
              </div>

              <div
                className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0 captionText3"
                id="slide-14-layer-15"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['235','235','235','235']"
                data-width="none"
                data-height="none"
                data-fontsize="['100','80','70','40']"
                data-lineheight="['58','40','40','30']"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="700"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
              >
                Alvarez & Hernández
              </div>

              <div
                className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0 captionText3-bold "
                id="slide-14-layer-2"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['205','205','205','205']"
                data-width="none"
                data-height="none"
                data-fontsize="['110','80','60','60']"
                data-lineheight="['58','58','58','58']"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1200"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
              >
                ABOGADOS
              </div>

              <div
                className="tp-caption small_thin_grey tp-resizeme rs-parallaxlevel-0"
                id="slide-14-layer-19"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['443','443','443','443']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1200"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{ zIndex: 8, whitespace: "nowrap" }}
              >
                <a
                  className="btn btn-default-alt rev-slider-btn-1"
                  href="/contacto"
                >
                  Contáctanos
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <section
        className="testimonial-area type-1-bg"
        style={{ paddingBottom: "20px" }}
      >
        <div className="welcome-title-area">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="Title-area">
                  <h2>Noticias</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="testimonial-content-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-14">
                <div id="testimonial-demo" className="owl-carousel owl-theme">
                  {news.map((notice, idx) => {
                    return (
                      <div className="item" key={idx}>
                        <div className="owl-testimonial-content">
                          <i className="fa fa-quote-left"></i>
                          <h1 style={{ color: "#b9996d" }}>{notice.title}</h1>
                          <div
                            style={{
                              fontSize: "20px",
                              color: "black",
                              textAlign: "justify",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: `<p>${notice.content}</p>`,
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="testimonial-customNavigation"
                style={{ marginRight: "20px", maxHeight: "40px" }}
              >
                {news.length > 1 && (
                  <>
                    <a className="btn prev">
                      <i className="fa fa-long-arrow-left"></i>
                    </a>
                    <a className="btn next">
                      <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="welcome-area">
        <div className="welcome-title-area">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="Title-area">
                  <h2>
                    Sobre <br />
                    Nosotros
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wellcome-asset">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="asset-content">
                  <h2>
                    {" "}
                    <span>
                      <i className="fa fa-quote-right"></i>
                    </span>
                  </h2>
                  <p style={{ color: "black", fontSize: "20px" }}>
                    Somos una firma de abogados especialistas en el manejo de
                    asuntos jurídicos, para personas y empresas. <br />
                    Contamos con experiencia en la prestación de asesoría
                    jurídica en diferentes áreas del derecho. <br />
                    <br /> Ofrecemos un análisis integral de soluciones
                    jurídicas para nuestro cliente, de manera que sea
                    beneficioso para la defensa de sus intereses, siendo
                    entonces la prevención y la celeridad los instrumentos
                    propios de una labor exitosa, como la hasta ahora
                    desempeñada.
                  </p>
                  <p style={{ color: "black", fontSize: "20px" }}>
                    Entendemos las limitaciones de tiempo de nuestros clientes,
                    motivo por el que utilizamos las herramientas tecnológicas
                    para tener contacto con ellos, enviar y recibir documentos,
                    entre otros.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="asset-button text-center">
                <a href="/sobre-nosotros">
                  <button type="button" className="btn btn-default">
                    Leer más
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="help-area">
          <div className="container">
            <div className="row">
              <div className=" col-sm-12 help-custom-padding">
                <div className="help-text-heading">
                  <h2>Nosotros te proporcionamos ayuda legal</h2>
                  <p>
                    En
                    <span style={{ color: "#b9996d" }}>
                      {" Alvarez & Hernández"}
                    </span>
                    , te brindamos asesoría legal, apoyo, representación
                    judicial y resultados de alta calidad para tus asuntos
                    legales.
                  </p>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <div className="help-content">
                      <i className="fa fa-globe"></i>
                      <h2>Asesoría a colombianos en el exterior</h2>
                      <p>
                        Asesoramos jurídicamente a colombianos residentes en el
                        exterior.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <div className="help-content">
                      <i className="fa fa-gavel"></i>
                      <h2>Trabajamos con abogados expertos</h2>
                      <p>
                        Estamos capacitados para obtener los mejores resultados
                        en tu caso.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <div className="help-content">
                      <i className="fa fa-home"></i>
                      <h2>Te asistimos</h2>
                      <p>
                        Nuestro domicilio principal se encuentra en Bogotá, no
                        obstante ofrecemos nuestros servicios en los
                        departamentos de Cundinamarca, Boyacá, Magdalena y
                        Tolima.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <div className="help-content">
                      <i className="fa fa-file-text"></i>
                      <h2>Revisamos tu caso</h2>
                      <p>
                        Contamos con abogados especializados que analizarán en
                        detalle tu caso para ofrecerte soluciones viables.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="practice-area">
        <div className="welcome-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="Title-area">
                  <h2>Áreas de Práctica</h2>
                  <p>
                    En estos años de ejercicio profesional, hemos adquirido la
                    capacidad de representar, acompañar y asesorar a personas
                    naturales y jurídicas con una gran variedad de servicios
                    legales de alta calidad y eficiencia:{" "}
                  </p>
                </div>
              </div>
              <div className="col-xs-10 col-sm-3">
                <a href="/areas-practica">
                  <div className="asset-button btn-text-left text-right top-margin">
                    <button type="button" className="btn btn-default">
                      Ver todas las áreas de práctica
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="practice-content clearfix">
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding">
                <div className="p-c-details text-center">
                  <div className="p-c-default pc-custom-defult">
                    <i className="fa fa-umbrella"></i>
                    <h2>
                      <a href="/areas-practica">Seguros</a>
                    </h2>
                  </div>
                  <div className="p-c-hover text-center">
                    <i className="fa fa-umbrella"></i>
                    <h2>
                      <a href="/areas-practica">Seguros</a>
                    </h2>
                    <p style={{ fontSize: "17px" }}>
                      Representamos judicial y extrajudicialmente en materia de
                      seguros.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding">
                <div className="p-c-details text-center">
                  <div className="p-c-default pc-custom-defult">
                    <i className="fa  fa-user"></i>
                    <h2>
                      <a href="/areas-practica">Laboral</a>
                    </h2>
                  </div>
                  <div className="p-c-hover text-center">
                    <i className="fa  fa-user"></i>
                    <h2>
                      <a href="/areas-practica">Laboral</a>
                    </h2>
                    <p style={{ fontSize: "17px" }}>
                      Asesoramos a trabajadores y empresas en el desarrollo de
                      sus vínculos laborales.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding">
                <div className="p-c-details text-center">
                  <div className="p-c-default pc-custom-defult">
                    <i className="fa fa-briefcase"></i>
                    <h2>
                      <a href="/areas-practica">Corporativo</a>
                    </h2>
                  </div>
                  <div className="p-c-hover text-center">
                    <i className="fa fa-briefcase"></i>
                    <h2>
                      <a href="/areas-practica">Corporativo</a>
                    </h2>
                    <p style={{ fontSize: "17px" }}>
                      Asistimos a su empresa en todo lo relacionado con aspectos
                      legales.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding">
                <div className="p-c-details text-center">
                  <div className="p-c-default pc-custom-defult">
                    <i className="fa fa-file-text"></i>
                    <h2>
                      <a href="/areas-practica"></a>
                      Administrativo
                    </h2>
                  </div>
                  <div className="p-c-hover text-center">
                    <i className="fa fa-file-text"></i>
                    <h2>
                      <a href="/areas-practica">Administrativo</a>
                    </h2>
                    <p style={{ fontSize: "17px" }}>
                      Contamos con experiencia en las diferentes áreas de
                      derecho administrativo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding">
                <div className="p-c-details text-center">
                  <div className="p-c-default">
                    <i className="fa fa-money"></i>
                    <h2>
                      <a href="/areas-practica">Pensiones</a>
                    </h2>
                  </div>
                  <div className="p-c-hover text-center">
                    <i className="fa fa-money"></i>
                    <h2>
                      <a href="/areas-practica">Pensiones</a>
                    </h2>
                    <p style={{ fontSize: "17px" }}>
                      Resolvemos sus inquietudes y tramitamos sus peticiones
                      pensionales.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding">
                <div className="p-c-details text-center">
                  <div className="p-c-default">
                    <i className="fa fa-bank"></i>
                    <h2>
                      <a href="/areas-practica">Civil</a>
                    </h2>
                  </div>
                  <div className="p-c-hover text-center">
                    <i className="fa fa-bank"></i>
                    <h2>
                      <a href="/areas-practica">Civil</a>
                    </h2>
                    <p style={{ fontSize: "17px" }}>
                      Atendemos las inquietudes y conflictos entre particulares.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding">
                <div className="p-c-details text-center">
                  <div className="p-c-default">
                    <i className="fa  fa-clipboard"></i>
                    <h2>
                      <a href="/areas-practica">Acciones de tutela</a>
                    </h2>
                  </div>
                  <div className="p-c-hover text-center">
                    <i className="fa  fa-clipboard"></i>
                    <h2>
                      <a href="/areas-practica">Acciones de tutela</a>
                    </h2>
                    <p style={{ fontSize: "17px" }}>
                      Actuamos ante la vulneración de tus derechos
                      fundamentales.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 no-padding">
                <div className="p-c-details text-center">
                  <div className="p-c-default">
                    <i className="fa fa-globe" style={{ textSize: "13px" }}></i>
                    <h2>
                      <a href="/areas-practica">En el exterior</a>
                    </h2>
                  </div>
                  <div className="p-c-hover text-center">
                    <i className="fa fa-globe"></i>
                    <h2>
                      <a href="/areas-practica">En el exterior</a>
                    </h2>
                    <p style={{ fontSize: "17px" }}>
                      Asesoramos jurídicamente a colombianos residentes en el
                      exterior.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-team-area">
        <div className="welcome-title-area">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="Title-area">
                  <h3>Nuestro Equipo</h3>
                  <h2>
                    Conoce los integrantes de{" "}
                    <span style={{ color: "#b9996d" }}>
                      Alvarez & Hernández
                    </span>{" "}
                    Abogados
                  </h2>
                  <p>
                    Contamos con un grupo de profesionales y personal de apoyo,
                    con amplia experiencia en el sector asegurador y
                    reconocimiento en la gestión administrativa.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">
                <a href="/abogados">
                  <div className="asset-button btn-text-left text-right top-margin">
                    <button type="button" className="btn btn-default">
                      Conoce todo el equipo
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="team-member-area">
          <div className="container">
            <div className="row">
              {lawyers
                .filter((l) => l.principal)
                .map((lawyer, idx) => {
                  return (
                    <div className="col-md-3 col-sm-6 col-xs-12" key={idx}>
                      <div className="team-describe">
                        <div className="team-content">
                          <div className="team-img">
                            <img src={lawyer.img} alt="team-member" />
                          </div>
                          <div className="about-team-member text-center">
                            <div className="team-describe-content">
                              <p>{lawyer.about}</p>
                            </div>
                          </div>
                        </div>
                        <div className="member-name text-center">
                          <h2>
                            <Link to={`abogados/${lawyer.name}`}>
                              {lawyer.name}
                            </Link>
                          </h2>
                          <p>{lawyer.resume}</p>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      <section className="contact-us contact-us-4">
        <div className="welcome-title-area">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="Title-area contact-title">
                  <h3>¡No dudes en preguntar!</h3>
                  <h2>Contacto</h2>
                  <p>
                    Siéntete libre de contarnos tus inquietudes, y uno de
                    nuestros abogados asociados te contactará tan pronto como
                    sea posible, ¡estamos para ayudarte!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <div className="container contact-bg no-padding">
            <form action="/email" method="post">
              <div className="row">
                <div className="col-sm-4">
                  <input
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="Tu Nombre"
                  />
                </div>
                <div className="col-sm-4">
                  <input
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Tu Correo"
                  />
                </div>
                <div className="col-sm-12">
                  <textarea
                    className="form-control"
                    rows="5"
                    name="message"
                    id="message"
                    placeholder="Detalles del caso, o bien, dejanos tu número de contacto y nos comunicamos contigo."
                  ></textarea>
                </div>
                <div className="col-sm-4 col-md-3 c-c-padding">
                  <button type="submit" className="btn btn-dm">
                    Envía tu solicitud
                  </button>
                </div>
                <div className="col-sm-8 col-md-9">
                  <div className="form-right-text">
                    <p>
                      Completa el formulario para recibir una consulta inicial y
                      confidencial. No te preocupes, nunca compartiremos tu
                      información ni la usaremos para enviar correo no deseado
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>{" "}
    </>
  );
};

export default Home;
