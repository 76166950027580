import { React } from "react";
import pin from "../static/images/pin.png";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const MyMap = (props) => {
  return (
    <div style={{ display: "block", position: "relative" }}>
      <Map
        google={props.google}
        style={props.style}
        zoom={16}
        initialCenter={{ lat: 4.61986, lng: -74.067399 }}
      >
        <Marker icon={pin} title="Edificio Casa de bolsa" />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAndm-f1bpmViBP6OUmbulsAglkkd_w69Y",
})(MyMap);
