import fredy from '../static/images/team-member-2.webp'
import ricardo from '../static/images/ricardo.webp'
import ana from '../static/images/ana.webp'
import kherynn from '../static/images/kheryn.webp'
import vanessa from '../static/images/vanessa.webp'
import nicolas from '../static/images/nicolas.webp'
import defaultImg from '../static/images/team-member-5.webp';

const lawyers = [
    {
        name: 'JOHN FREDY ALVAREZ CAMARGO',
        resume: 'Abogado de la Universidad Pedagógica y Tecnológica de Colombia, especialista en derecho de seguros',
        about: 'Abogado egresado de la Universidad Pedagógica y Tecnológica de Colombia, especialista en derecho de seguros de la Universidad Javeriana y cursando especialización en Derecho Administrativo en la Universidad Santo Tomas, con experiencia profesional de más de 10 años, 8 de ellos en materia aseguradora y litigio',
        exp: 'Se ha desempeñado como abogado de la gerencia de procesos judiciales de La Previsora S.A. Compañía de Seguros, abogado externo del Departamento de Boyacá, asesor jurídico de personas jurídicas y naturales',
        mail: 'fredyalvarez@ayhabogados.com',
        img: fredy,
        principal: true
    },
    {
        name: 'RAFAEL RICARDO HERNANDEZ BARRERA',
        resume: 'Abogado de la Universidad Pedagógica y Tecnológica de Colombia, especialista en derecho procesal y magister en derechos humanos',
        about: 'Abogado de la Universidad Pedagógica y Tecnológica de Colombia, especialista en derecho procesal de la Universidad Nacional de Colombia y magíster en derechos humanos, cursando actualmente especialización en derecho público en la Universidad Externado de Colombia. Diplomados en derecho disciplinario, contratación estatal y en arbitramento',
        exp: 'Cuenta con experiencia de más de 12 años, 6 de ellos en el sector público y 6 años en seguros como abogado litigante. Se ha desempeñado en la Rama Judicial (Juzgados Administrativos, Tribunal Administrativo de Boyacá), Administradora Colombiana de Pensiones Colpensiones, asesor de entidades públicas, empresas y personas naturales',
        mail: 'ricardohernandez@ayhabogados.com',
        img: ricardo,
        principal: true
    },
    {
        name: 'LINA ALEJANDRA TORRES PEREZ',
        resume: 'Abogada de la Universidad Nacional de Colombia, especialista en derecho de familia ',
        about: 'Abogada y especialista en derecho de familia de la Universidad Nacional de Colombia',
        exp: 'Cuenta con experiencia profesional de más de 6 años en materia litigiosa, especialmente en procesos ejecutivos e hipotecarios, desempeñándose en distintas firmas del sector.  Así como en representación judicial dentro de procesos administrativos, laborales y fiscales en defensa de compañías aseguradoras',
        mail: 'linatorres@ayhabogados.com',
        img: defaultImg,
        principal: false
    },
    {
        name: 'ANA MARIA MARTINEZ MARTINEZ',
        resume: 'Abogada egresada de la Universidad Libre, especialista en derecho de Administrativo Laboral',
        about: 'Abogada de la Universidad Libre, especialista en Derecho de Administrativo Laboral de la Universidad Externado de Colombia. Diplomados en Seguridad social y cursos en Derecho laboral y Electoral',
        exp: `Cuenta con experiencia profesional de más de 7 años en asesoría legal en el área de derecho laboral, tanto para entidades privadas como personas naturales. Así como en representación judicial dentro de procesos administrativos, laborales y civiles en defensa de compañías aseguradoras.
Se encuentra vinculada con la firma desde el año 2017 y actualmente se desempeña como Coordinadora de Procesos Judiciales. `,
        mail: ' anamartinez@ayhabogados.com',
        img: ana,
        principal: false
    },
    {
        name: 'KHERYNN LOPEZ GOMEZ',
        resume: 'Abogada egresada de la Universidad Libre, con experiencia de más de 4 años como encargada de control',
        about: 'Abogada de la Universidad Libre, cuenta con experiencia profesional de más de 4 años',
        exp: `Experiencia profesional de más 4 años como encargada del control y sustanciadora de los procesos de responsabilidad fiscal en la zona centro del país, con experiencia en manejo de procesos judiciales en los diferentes despachos en el área de seguros, civil, laboral y administrativa.
        Se encuentra vinculada con la firma desde el año 2017 y actualmente se desempeña como Coordinadora de Procesos Fiscales.`,
        mail: 'kherynnlopez@ayhabogados.com',
        img: kherynn,
        principal: true
    },
    {
        name: 'WENDY VANESSA GONZALEZ',
        resume: 'Abogada de la Universidad Católica, con experiencia de más de 3 años en manejo de procesos concursales ',
        about: 'Abogada de la Universidad Católica, con experiencia de más de 3 años en manejo de procesos concursales ante la Superintendencia de Sociedades',
        exp: 'Se encuentra vinculada en la firma desde el año 2019, con experiencia de más de 3 años en manejo de procesos concursales ante la Superintendencia de Sociedades, investigaciones de responsabilidad fiscal en la zona centro del país, así como en asuntos judiciales en los diferentes despachos en el área civil, laboral y administrativa.',
        mail: 'wendygonzalez@ayhabogados.com',
        img: vanessa,
        principal: true
    },
    {
        name: 'NICOLAS CARO CASTRO',
        resume: 'Estudiante de noveno semestre de Derecho de la Universidad Militar Nueva Granada',
        about: 'Estudiante de último año Derecho de la Universidad Militar Nueva Granada. con diplomados en derecho internacional humanitario, Derechos humanos y Contratación estatal ',
        exp: 'Con experiencia de 2 años en control y vigilancia de procesos judiciales, así como asesoría jurídica en procesos de contratación estatal',
        mail: 'nicolascaro@ayhabogados.com',
        img: nicolas,
        principal: false
    }
];


export default lawyers.sort((a, b) => a.name.localeCompare(b.name));